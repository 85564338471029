<template>
  <UiTransition :name="name" mode="out-in" :leave-absolute="leaveAbsolute">
    <div
      v-if="debouncedLoading"
      :class="classNames.loadingWrapper"
      :style="{
        '--loader-container-size': containerSize ? toUnit(containerSize) : '100%',
      }"
    >
      <UiFinqLoader v-if="type === 'finq'" :size="size" />
      <UiSpinner v-else :size="size" />
    </div>

    <div v-else :class="classNames.wrapper">
      <slot />
    </div>
  </UiTransition>
</template>

<script lang="ts">
import { type ClassValue } from "clsx"

import { type UiSpinnerVariantsProps } from "./Spinner.vue"

export interface UiLoadingAnimationClasses {
  loadingWrapper: ClassValue
  wrapper: ClassValue
}
</script>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name?: string
    loading: boolean
    size?: UiSpinnerVariantsProps["size"]
    containerSize?: number | string
    leaveAbsolute?: boolean
    class?: ClassValue
    classes?: Partial<UiLoadingAnimationClasses>
    type?: "finq"
  }>(),
  { name: "scrollY" }
)

// Makes sure loading wont flicker too much if response returns too fast
const debouncedLoading = refDebounced(toRef(props, "loading"), 300)

const classNames = computed(
  (): Required<UiLoadingAnimationClasses> => ({
    loadingWrapper: cn(
      "relative my-auto flex max-h-[--loader-container-size] min-h-[--loader-container-size] w-full items-center justify-center",
      props.classes?.loadingWrapper
    ),
    wrapper: cn("loader-animation__wrapper ui-loader-wrapper", props.class),
  })
)
</script>
